const documentClickHandler = (evt) => {
  const footerDropEl = evt.target.closest('.footer__title--drop');

  if (footerDropEl) {
    evt.preventDefault();

    const footerCompanyEl = footerDropEl.closest('.footer__company');
    footerCompanyEl.classList.toggle('is-active');
  }
};

const initFooter = () => {
  const footerEl = document.querySelectorAll('.footer');

  if (!footerEl.length) {
    return;
  }

  document.addEventListener('click', documentClickHandler);
};

export {initFooter};
