const initIntro = () => {
  const introEl = document.querySelector('.intro');

  if (!introEl) {
    return;
  }

  const introBtnEl = introEl.querySelector('.intro__btn');
  const toolsEl = document.querySelector('.tools');

  introBtnEl.addEventListener('click', (e) => {
    e.preventDefault();

    const y = toolsEl.getBoundingClientRect().top + window.pageYOffset - Math.max(((window.innerHeight - toolsEl.offsetHeight) / 2), 50);

    window.scrollTo({top: y, behavior: 'smooth'});
  });
};

export {initIntro};
