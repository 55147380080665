const initClientsSlider = () => {
  const clientsSliderEls = document.querySelectorAll('.clients-slider');

  if (!clientsSliderEls.length) {
    return;
  }

  let observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      entry.target.classList.toggle('is-active', entry.isIntersecting);

      if (entry.target.swiper) {
        if (entry.isIntersecting) {
          entry.target.swiper.autoplay.start();
        } else {
          entry.target.swiper.autoplay.stop();
        }
      }

    });
  });

  clientsSliderEls.forEach((sliderEl) => {
    const containerEl = sliderEl.querySelector('.swiper-container');

    const swiper = new window.Swiper(containerEl, {
      loop: true,
      slidesPerView: 'auto',
      centeredSlides: true,
      speed: 5000,
      breakpoints: {
        768: {
          centeredSlides: false,
        },
      },
      autoplay: {
        delay: 0,
      },
    });

    sliderEl.swiper = swiper;

    observer.observe(sliderEl);
  });
};

export {initClientsSlider};
