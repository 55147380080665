const initHeader = () => {
  const headerEl = document.querySelector('.header');

  if (!headerEl) {
    return;
  }

  let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;

  window.addEventListener('scroll', (e) => {
    const st = window.pageYOffset || document.documentElement.scrollTop;

    if (window.pageYOffset > 120) {
      headerEl.classList.add('header--fixed');
    } else {
      headerEl.classList.remove('header--fixed');
    }

    if (st > lastScrollTop) {
      // downscroll code
      if (window.pageYOffset > 500) {
        headerEl.classList.add('header--top');
      }
    } else {
      headerEl.classList.remove('header--top');
    }

    lastScrollTop = st <= 0 ? 0 : st;
  });
};

export {initHeader};
