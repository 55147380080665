const initMainNav = () => {
  const mainNavEl = document.querySelector('.main-nav');

  if (!mainNavEl) {
    return;
  }

  const moreSublistEl = mainNavEl.querySelector('[data-more-sublist]');
  const mainListEl = mainNavEl.querySelector('[data-main-list]');
  const moreItemEl = mainNavEl.querySelector('[data-more]');
  const menuToggleEl = mainNavEl.querySelector('.main-nav__toggle');

  const smallDesktopBreakpoint = window.matchMedia('(min-width: 768px) and (max-width: 1279px)');
  const menuItemEls = mainNavEl.querySelectorAll('.main-nav__item');
  const mobileBreakpoint = window.matchMedia('(max-width: 767px)');


  const breakpointChecker = () => {
    if (smallDesktopBreakpoint.matches) {
      const menuMoreEls = mainNavEl.querySelectorAll('[data-hide-in-more]');

      menuMoreEls.forEach((itemEl) => {
        const linkEl = itemEl.querySelector('.main-nav__link');

        itemEl.classList.remove('main-nav__item');
        itemEl.classList.add('main-nav__subitem');

        linkEl.classList.remove('main-nav__link');
        linkEl.classList.add('main-nav__sublink');

        moreSublistEl.appendChild(itemEl);
      });
    } else {
      const menuMoreEls = moreSublistEl.querySelectorAll('[data-hide-in-more]');

      menuMoreEls.forEach((itemEl) => {
        const linkEl = itemEl.querySelector('.main-nav__sublink');

        itemEl.classList.remove('main-nav__subitem');
        itemEl.classList.add('main-nav__item');

        linkEl.classList.remove('main-nav__sublink');
        linkEl.classList.add('main-nav__link');

        mainListEl.insertBefore(itemEl, moreItemEl);
      });
    }
  };

  breakpointChecker();
  smallDesktopBreakpoint.addListener(breakpointChecker);

  if (menuToggleEl) {
    menuToggleEl.addEventListener('click', (e) => {
      e.preventDefault();

      document.body.classList.toggle('is-show-menu');

      if (document.body.classList.contains('is-show-menu')) {
        window.scrollLock.disableScrolling();
      } else {
        window.scrollLock.enableScrolling();
      }
    });

    const mobileBreakpointChecker = () => {
      if (!mobileBreakpoint.matches) {
        document.body.classList.remove('is-show-menu');
        window.scrollLock.enableScrolling();
      }
    };

    mobileBreakpointChecker();
    mobileBreakpoint.addListener(mobileBreakpointChecker);
  }

  document.addEventListener('click', (e) => {
    const menuItemEl = e.target.closest('.main-nav__item');

    if (!menuItemEl) {
      menuItemEls.forEach((itemEl) => itemEl.classList.remove('is-active'));
    }
  });

  menuItemEls.forEach((itemEl) => {
    const linkEl = itemEl.querySelector('.main-nav__link svg');
    const sublistEl = itemEl.querySelector('.main-nav__sublist');

    if (sublistEl) {
      linkEl.addEventListener('click', (e) => {
        if (window.matchMedia('(pointer: coarse)').matches) {
          e.preventDefault();

          itemEl.classList.toggle('is-active');
          itemEl.classList.remove('is-focus');
        }
      });

      linkEl.addEventListener('focus', (e) => {
        if (window.matchMedia('(pointer: coarse)').matches) {
          e.preventDefault();
          itemEl.classList.add('is-focus');
        }
      });

      linkEl.addEventListener('blur', (e) => {
        if (window.matchMedia('(pointer: coarse)').matches) {
          e.preventDefault();
          itemEl.classList.remove('is-focus');
        }
      });
    }
  });

};

export {initMainNav};
