
const initMailLinks = () => {
  const mailLinks = document.querySelectorAll('.link[href^="mailto"]');

  if (!mailLinks.length) {
    return;
  }

  const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  document.addEventListener('copy', (event) => {
    const selection = document.getSelection();
    if (pattern.test(selection.toString().toLowerCase())) {
      event.clipboardData.setData('text/plain', selection.toString().toLowerCase());
      event.preventDefault();
    }
  });
};

export {initMailLinks};
