const isInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  const windowWidth = (window.innerWidth || document.documentElement.clientWidth);
  const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
  const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

  return (vertInView && horInView);
};

const initArticle = () => {
  const articleEl = document.querySelector('.article');

  if (!articleEl) {
    return;
  }

  const textWrapEl = articleEl.querySelector('.article__text');

  Array.from(textWrapEl.children).forEach((el, i) => {
    el.setAttribute('data-sal', 'slide-up');

    if (isInViewport(el)) {
      el.setAttribute('data-sal-delay', i * 50);
    }
  });
};

export {initArticle};
