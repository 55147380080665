const initPersonSlider = () => {
  const personSliderEls = document.querySelectorAll('.person-slider');

  if (!personSliderEls.length) {
    return;
  }

  let timer;

  let observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        clearTimeout(timer);

        const slide1 = () => {
          timer = setTimeout(slide2, 2550);
        };

        const slide2 = () => {
          clearTimeout(timer);
          const activeSlideEl = entry.target.querySelector('.is-active');
          const rightSlideEl = entry.target.querySelector('.is-right');

          activeSlideEl.classList.remove('is-active');
          activeSlideEl.classList.add('is-right');

          rightSlideEl.classList.remove('is-right');
          rightSlideEl.classList.add('is-active');

          timer = setTimeout(slide3, 4200);
        };

        const slide3 = () => {
          clearTimeout(timer);
          const newActiveSlideEl = entry.target.querySelector('.is-active');
          const leftSlideEl = entry.target.querySelector('.is-left');

          newActiveSlideEl.classList.remove('is-active');
          newActiveSlideEl.classList.add('is-left');

          leftSlideEl.classList.remove('is-left');
          leftSlideEl.classList.add('is-active');

          timer = setTimeout(() => slide1(true), 3600);
        };

        slide1();
      } else {
        clearTimeout(timer);
      }
    });
  });

  personSliderEls.forEach((sliderEl) => {
    observer.observe(sliderEl);
  });

};

export {initPersonSlider};
