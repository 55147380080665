import {iosVhFix} from './utils/ios-vh-fix';
import {initModals} from './modules/modals/init-modals';
import {initCustomSelect} from './modules/form/init-custom-select';
import {initFormValidate} from './modules/form/init-form-validate';
import {initPersonSlider} from './modules/init-person-slider';
import {initClientsSlider} from './modules/init-clients-slider';
import {initAccordions} from './modules/accordion/init-accordion';
import {initMainNav} from './modules/init-main-nav';
import {initTabletSlider} from './modules/init-tablet-slider';
import {initFooter} from './modules/init-footer';
import {initVideo} from './modules/init-video';
import {initHeader} from './modules/init-header';
import {initArticle} from './modules/init-article';
import {initIntro} from './modules/init-intro';
import {initMailLinks} from './modules/init-maillnks';

// ---------------------------------

window.addEventListener('DOMContentLoaded', () => {

  // Utils
  // ---------------------------------

  iosVhFix();
  initMainNav();

  // Modules
  // ---------------------------------

  // все скрипты должны быть в обработчике 'DOMContentLoaded', но не все в 'load'
  // в load следует добавить скрипты, не участвующие в работе первого экрана
  window.addEventListener('load', () => {
    initModals();
    initCustomSelect();
    initFormValidate();
    initPersonSlider();
    initClientsSlider();
    initAccordions();
    initTabletSlider();
    initFooter();
    initVideo();
    initHeader();
    initArticle();
    initIntro();
    initMailLinks();

    window.sal({
      threshold: 0.5,
      once: true,
    });

    window.sal({
      threshold: 0.2,
      once: true,
      selector: '[data-sal-small]',
    });

    const lazyloadInstance = new window.LazyLoad();
  });
});

// ---------------------------------

// ❗❗❗ обязательно установите плагины eslint, stylelint, editorconfig в редактор кода.

// привязывайте js не на классы, а на дата атрибуты (data-validate)

// вместо модификаторов .block--active используем утилитарные классы
// .is-active || .is-open || .is-invalid и прочие (обязателен нейминг в два слова)
// .select.select--opened ❌ ---> [data-select].is-open ✔️

// выносим все в дата атрибуты
// url до иконок пинов карты, настройки автопрокрутки слайдера, url к json и т.д.

// для адаптивного JS используейтся matchMedia и addListener
// const breakpoint = window.matchMedia(`(min-width:1024px)`);
// const breakpointChecker = () => {
//   if (breakpoint.matches) {
//   } else {
//   }
// };
// breakpoint.addListener(breakpointChecker);
// breakpointChecker();

// используйте .closest(el)
