const initVideo = () => {
  const videoEls = document.querySelectorAll('.video');

  if (!videoEls.length) {
    return;
  }

  // videoEls.forEach((videoEl) => {
  //   const frameEl = videoEl.querySelector('video');
  //   const playEl = videoEl.querySelector('.video__btn');

  //   playEl.addEventListener('click', (e) => {
  //     e.preventDefault();

  //     frameEl.controls = true;
  //     frameEl.play();
  //   });

  //   frameEl.addEventListener('play', (e) => {
  //     videoEl.classList.add('is-play');
  //   });

  //   frameEl.addEventListener('pause', (e) => {
  //     videoEl.classList.remove('is-play');
  //     frameEl.controls = false;
  //   });
  // });

  const observer = new IntersectionObserver((entries) => {
    const target = entries[0].target;

    if (entries[0].isIntersecting) {
      target.play();
    } else {
      target.pause();
    }
  }, {
    threshold: [0.7],
  });

  videoEls.forEach((videoElement) => {
    const frameElement = videoElement.querySelector('video');

    observer.observe(frameElement);
  });

  const onDocumentClick = (evt) => {
    const muteButtonElement = evt.target.closest('.video__sound-btn');

    if (muteButtonElement) {
      const videoElement = muteButtonElement.closest('.video');
      const frameElement = videoElement.querySelector('video');

      videoElement.classList.toggle('is-muted');
      frameElement.muted = videoElement.classList.contains('is-muted');

      muteButtonElement.setAttribute('aria-label', videoElement.classList.contains('is-muted') ? 'Включить звук' : 'Выключить звук');
    }
  };

  document.addEventListener('click', onDocumentClick);
};

export {initVideo};
