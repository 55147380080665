const initTabletSlider = () => {
  const tabletSliderEls = document.querySelectorAll('[data-tablet-slider]');

  if (!tabletSliderEls.length) {
    return;
  }

  const mobileBreakpoint = window.matchMedia('(max-width: 1023px)');

  const breakpointChecker = () => {
    tabletSliderEls.forEach((servicesEl) => {
      const sliderEl = servicesEl.querySelector('.swiper-container');

      if (sliderEl) {
        if (mobileBreakpoint.matches) {
          if (!sliderEl.swiper) {
            sliderEl.swiper = new window.Swiper(sliderEl, {
              loop: false,
              slidesPerView: 'auto',
            });
          }
        } else {
          if (sliderEl.swiper) {
            sliderEl.swiper.destroy(true, true);
            sliderEl.swiper = null;
          }
        }
      }
    });
  };

  breakpointChecker();
  mobileBreakpoint.addListener(breakpointChecker);
};

export {initTabletSlider};
